<template>
  <div class="freelancing-network">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url('img/freelancers/header-freelance.jpg'); background-size: cover"
    >
      <div class="viewport">
        <h1>access leading technology companies</h1>
        <p>
          Enjoy the freedom to work in the areas that fit your experience, expertise, availability
          and interests.
        </p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="benefits">
        <h3>Why work with us?</h3>
        <p>
          Whether you’re a software developer, UX designer, QA expert or in the areas of technical
          operations or support, Comstream gives you the freedom to choose the jobs you want across
          our network of discerning business partners.
        </p>
      </div>

      <div class="benefit-boxes d-flex justify-content-between flex-wrap">
        <div class="box d-flex flex-wrap">
          <img src="img/icons/icons8-heart-puzzle-100.png" alt="inspiring projects" />
          <h4>Exciting and inspiring projects</h4>
          <p>
            By being on our roster, you’ll join high-calibre professionals and have access to
            innovative, challenging and rewarding projects with visionary industry leaders.
          </p>
        </div>

        <div class="box d-flex flex-wrap">
          <img src="img/icons/icons8-box-100.png" alt="Expand your portfolio" />
          <h4>Expand your portfolio</h4>
          <p>
            Work on a wide variety of different clients and verticals, allowing you to gain valuable
            experience and strengthen your professional skills.
          </p>
        </div>

        <div class="box d-flex flex-wrap">
          <img src="img/icons/icons8-connected-people-100.png" alt="Build your network" />
          <h4>Build your network</h4>
          <p>
            Be part of a fast-growing community of talented technology specialists and enjoy the
            benefits of being in a high-quality network of professionals.
          </p>
        </div>

        <div class="box d-flex flex-wrap">
          <img src="img/icons/icons8-puzzle-100.png" alt="icon" />
          <h4>Placements that fit</h4>
          <p>
            We take the time to understand your preferences and skills. By doing so, we can match
            you with projects that fit your experience, expertise, and interests.
          </p>
        </div>

        <div class="box d-flex flex-wrap">
          <img src="img/icons/icons8-crowd-100.png" alt="icon" />
          <h4>Feel part of the team</h4>
          <p>
            We ensure that you feel part of our team, and that of our client. As a freelancer,
            you’ll be treated with the same respect as any other staff member.
          </p>
        </div>

        <div class="box d-flex flex-wrap">
          <img src="img/icons/icons8-time-to-pay-100.png" alt="Payments on time" />
          <h4>Payments on time</h4>
          <p>
            As a Comstream freelancer, you won’t worry about payment or invoice issues. We’ll fully
            support you to ensure that your time is appropriately compensated.
          </p>
        </div>
      </div>

      <div class="call-to-action-boxes d-flex justify-content-between">
        <div
          class="box"
          style="background: url('img/freelancers/grow-passion-bg.jpg'); background-size: cover"
        >
          <div class="content">
            <h3>Grow your experience, work with your passion</h3>
            <p>
              If you’re a freelancer in one of the following areas, submit your CV with an
              introduction to start your journey with Comstream.
            </p>
            <router-link to="/submit-cv" class="btn btn-primary">Submit CV</router-link>
          </div>
          <div class="overlay"></div>
        </div>

        <div
          class="box"
          style="background: url('img/freelancers/next-step-bg.jpg'); background-size: cover"
        >
          <div class="content">
            <h3>Looking for the next step?</h3>
            <p>
              If you want to take your career to the next level, why not join our team full-time and
              enjoy unique opportunities in an exceptional work environment.
            </p>
            <router-link to="/careers" class="btn btn-primary">Learn more</router-link>
          </div>
          <div class="overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Freelancer Netowork | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.freelancing-network {
  .benefits {
    padding: 70px 0;
    h3 {
      text-align: center;
      font-size: 38px;
      font-weight: 700;
    }
    p {
      text-align: center;
      width: 75%;
      margin: 0 auto;
    }
  }
  .benefit-boxes {
    .box {
      width: 32%;
      margin-bottom: 40px;
      text-align: center;
      h4 {
        width: 100%;
        font-size: 26px;
        font-weight: 700;
        height: 50px;
        color: #000;
      }
      img {
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto 0px auto;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .freelancing-network {
    .benefits {
      h3 {
        font-size: 2rem;
      }
      p {
        width: 100%;
      }
    }
    .benefit-boxes {
      display: block !important;
      .box {
        width: 100%;
      }
    }
  }
}
</style>
